import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";
import { withFirebase } from '../../organisms/Firebase';
import moment from 'moment';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  noCourse: {
    padding: theme.spacing(3),
    minHeight: theme.spacing(44),
    backgroundImage: "url(no_course.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "top",
    color: theme.palette.common.white,
  },
  step: {
    minHeight: theme.spacing(44),
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    right:0,
    zIndex:0,
    overflow:'hidden',
  },
  bigNumber: {
    fontSize: "32rem",
    color: theme.palette.secondary.main,
    float:'right',
    marginTop:-theme.spacing(10),
    marginRight:-theme.spacing(3),
  },
  stepLink: {
    textDecoration: "none",
  },
})

function NoCourse({ classes, theme} ) {
  return (
    <Box className={classNames(classes.noCourse)}>
      <Typography variant="h4" style={{ marginBottom: theme.spacing(6), zIndex:1 }}>
        Your STEPS Course
      </Typography>
      <Box style={{ flexGrow: 2, zIndex:1, textAlign: 'center' }}>
        <Typography variant="h3" style={{ marginBottom: theme.spacing(2) }}>
            You have no upcoming STEPS sessions
        </Typography>
        <p>
          <Link to="/profile" style={{ color: theme.palette.common.white }}>
            Update your course dates.
          </Link>
        </p>
      </Box>
    </Box>
  )
}

function Step({ classes, theme, step, date, title, stepLink }) {
  return (
    <>
      <Box className={classNames(classes.step)}>
        <Typography className={classes.bigNumber} variant="h1">
          {step}
        </Typography>
      </Box>
      <Box style={{ minHeight: theme.spacing(44), padding: theme.spacing(3), zIndex:1, display: "flex", justifyContent: 'space-between', flexDirection:'column' }}>

        <Typography variant="h4">
          Your STEPS Course
        </Typography>

        <Box>
          {date}<br/>
          <Typography variant="h3">
            <Link className={classes.stepLink} to={stepLink}>
              Next STEP<br/>
              {title}
            </Link>
          </Typography>
        </Box>

        <Box>
          STEPS tips: Have you sent the email?
        </Box>

      </Box>
    </>
  );
}

class YourCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOfIntroduction: '',
      dateOfWeekOne: '',
      dateOfWeekFive: '',
      date: '',
      title: '',
      stepLink: '',
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      const getDates = async () => {
        await this.props.firebase.user(authUser.uid).once('value').then( (snapshot) => {
          if (snapshot.exists()){
            const data = snapshot.val();
            this.setState({
              dateOfIntroduction: data.dateOfIntroduction,
              dateOfWeekOne: data.dateOfWeekOne,
              dateOfWeekFive: data.dateOfWeekFive
            })
          }
        })
      }
      if (authUser) {
        getDates();
      }
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    const { theme, classes } = this.props;

    //Set Variables
    let upcomingEvent;
    let item;
    let step;
    let date;
    let title;
    let stepLink;

    //Get User Dates //What about if event is on same day ...
    const intro = moment().isSameOrBefore(this.state.dateOfIntroduction, 'd');
    const weekOne = moment().isSameOrBefore(this.state.dateOfWeekOne, 'd');
    const weekFive= moment().isSameOrBefore(this.state.dateOfWeekFive, 'd');

    //Derive All Dates
    const weekTwo = moment().isSameOrBefore(moment(this.state.dateOfWeekOne).add(8,'d'));
    const weekThree = moment().isSameOrBefore(moment(this.state.dateOfWeekOne).add(15,'d'));
    const weekFour = moment().isSameOrBefore(moment(this.state.dateOfWeekOne).add(22,'d'));

    const weekSix = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(8,'d'));
    const weekSeven = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(15,'d'));
    const weekEight = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(22,'d'));
    const weekNine = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(29,'d'));
    const weekTen = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(36,'d'));
    const weekEleven = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(43,'d'));
    const weekTwelve = moment().isSameOrBefore(moment(this.state.dateOfWeekFive).add(50,'d'));


    //Populate Steps
    if(intro) {
      upcomingEvent = true;
      step = "01";
      date = moment(this.state.dateOfIntroduction).format('DD MMM');
      title = "STEPS Introduction";
      stepLink = "/resources/introduction-session";
    //Week One
    } else if(weekOne) {
        upcomingEvent = true;
        step = "01";
        date = moment(this.state.dateOfWeekOne).format('DD MMM');
        title = "STEP 1";
        stepLink = "/resources/step-1-recognition";
    //Week Two
    } else if(weekTwo) {
        upcomingEvent = true;
        step = "02";
        date = moment(this.state.dateOfWeekOne).add(7,'d').format('DD MMM');
        title = "STEP 2";
        stepLink = "/resources/step-2-hope";
    //Week Three
    } else if(weekThree) {
        upcomingEvent = true;
        step = "03";
        date = moment(this.state.dateOfWeekOne).add(14,'d').format('DD MMM');
        title = "STEP 3";
        stepLink = "/resources/step-3-surrender";
    //Week Four
  } else if(weekFour) {
        upcomingEvent = true;
        step = "04";
        date = moment(this.state.dateOfWeekOne).add(21,'d').format('DD MMM');
        title = "STEP 4";
        stepLink = "/resources/step-4-responsibility";
    //Week Five
    } else if(weekFive) {
        upcomingEvent = true;
        step = "05";
        date = moment(this.state.dateOfWeekFive).format('DD MMM');
        title = "STEP 5";
        stepLink = "/resources/step-5-confession";
    //Week Six
    } else if(weekSix) {
        upcomingEvent = true;
        step = "06";
        date = moment(this.state.dateOfWeekFive).add(7,'d').format('DD MMM');
        title = "STEP 6";
        stepLink = "/resources/step-6-willingness";
    //Week Seven
    } else if(weekSeven) {
        upcomingEvent = true;
        step = "07";
        date = moment(this.state.dateOfWeekFive).add(14,'d').format('DD MMM');
        title = "STEP 7";
        stepLink = "/resources/step-7-courage";
    //Week Eight
  } else if(weekEight) {
        upcomingEvent = true;
        step = "08";
        date = moment(this.state.dateOfWeekFive).add(21,'d').format('DD MMM');
        title = "STEP 8";
        stepLink = "/resources/step-8-preparation";
    //Week Nine
    } else if(weekNine) {
        upcomingEvent = true;
        step = "09";
        date = moment(this.state.dateOfWeekFive).add(28,'d').format('DD MMM');
        title = "STEP 9";
        stepLink = "/resources/step-9-reconciliation";
    //Week Ten
    } else if(weekTen) {
        upcomingEvent = true;
        step = "10";
        date = moment(this.state.dateOfWeekFive).add(35,'d').format('DD MMM');
        title = "STEP 10";
        stepLink = "/resources/step-10-perseverance";
    //Week Eleven
    } else if(weekEleven) {
        upcomingEvent = true;
        step = "11";
        date = moment(this.state.dateOfWeekFive).add(42,'d').format('DD MMM');
        title = "STEP 11";
        stepLink = "/resources/step-11-connection";
    //Week Twelve
    } else if(weekTwelve) {
        upcomingEvent = true;
        step = "12";
        date = moment(this.state.dateOfWeekFive).add(49,'d').format('DD MMM');
        title = "STEP 12";
        stepLink = "/resources/step-12-sharing";

    //No Sessions
    } else {
      upcomingEvent = false;
    }

    if(upcomingEvent) {
      item = <Step classes={classes} theme={theme} step={step} date={date} title={title} stepLink={stepLink} />
    } else {
      item = <NoCourse classes={classes} theme={theme} />
    }

    return (
      item
    );
  }
}

export default withFirebase(withStyles(styles, { withTheme: true })(YourCourse))

import React from 'react';
import parse from 'html-react-parser';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function Paragraph({ content: { paragraph } }) {
  const theme = useTheme()
  return (
    <Typography variant="body1" component="div" style={{ marginBottom: theme.spacing(4) }}>
      { parse(paragraph) }
    </Typography>
  );
}

export default Paragraph;

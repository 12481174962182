import parse from 'html-react-parser';

import Typography from '@material-ui/core/Typography';

function Copyright(props) {
  return (
    <Typography variant="body2" component="div">
      {parse(props.copyright)}
    </Typography>
  );
}

export default Copyright;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../organisms/Firebase';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import  { BootstrapInput, BootstrapButton } from '../../atoms/Elements';
import { PasswordForgetLink } from '../../organisms/PasswordForget'

const styles = (theme) => ({
  form:{
    width:"100%",
  },
  labelText: {
    fontSize:'1.25rem'
  },
  submitWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  }
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class LogInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/dashboard');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit} className={classes.form}>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="email">Email</label>
          <BootstrapInput
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="password">Password</label>
          <BootstrapInput
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
          />
        </FormControl>
        <Box className={classes.submitWrapper}>
          <BootstrapButton disabled={isInvalid} type="submit" style={ {marginBottom:"32px" }}>
            Log In
          </BootstrapButton>
          <PasswordForgetLink />
        </Box>
        <br/>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const LogInForm = withRouter(withFirebase(withStyles(styles, { withTheme: true })(LogInFormBase)));

export default LogInForm;

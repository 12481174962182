import React from 'react';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  wrapper: {
  },
})

function day_of_the_month(d) {
   return (d.getDate() < 10 ? '0' : '') + d.getDate();
}

function twelve_hour(hour) {
  const hours = (hour % 12) || 12;
  return hours
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

function UpcomingEvent(props) {
  const { theme, upcomingEvent: { name, datetime_start, datetime_end, signup_options }, loading } = props;

  const dateXpre = datetime_start ? datetime_start.replace(/\s/, 'T') : ''
  const dateX = new Date(dateXpre);
  const date = day_of_the_month(dateX);
  const month = String(dateX.getMonth())

  const hourZ = twelve_hour((dateX.getHours()))
  const minStart = (dateX.getMinutes()<10?'0':'') + dateX.getMinutes()


  const dateYpre = datetime_end ? datetime_end.replace(/\s/, 'T') : ''
  const dateY = new Date(dateYpre);
  const hourEnd = twelve_hour((dateY.getHours()))
  const minEnd = (dateY.getMinutes()<10?'0':'') + dateY.getMinutes()

  let linkUrl = "";

  if (loading) {
    linkUrl = "";
  } else {
    linkUrl = signup_options.tickets.url;
  }

  return (
    <Box style={{ clear: 'both' }}>
      <Divider style={{
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        }}
      />
      <a href={linkUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Box style={{
            paddingTop:theme.spacing(2),
            paddingBottom:theme.spacing(2),
            display: 'flex',
          }}
        >
          <Typography variant="h2" style={{
            color: '#befefe',
            marginRight: theme.spacing(2.5),
            backgroundColor: theme.palette.primary.light,
            lineHeight: '2.4rem',
            paddingTop: '0.8rem',
            paddingLeft: '-1.1rem',
            marginTop: '0.25rem',
            marginBottom: '0rem',
            minWidth: "5rem",
            textAlign: "center",
          }}>
            {date}
          </Typography>
          <div>
            <Typography variant="h6">{name}</Typography>
            {date} {monthNames[month]} | {hourZ}.{minStart} - {hourEnd}.{minEnd}
          </div>
        </Box>
      </a>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(UpcomingEvent)

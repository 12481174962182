import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  divider: {
    borderBottomColor: "#666",
    "&:last-of-type": {
      borderBottom: "0",
    }
  },
});

function ChapterLink(props) {
  const { classes, chapterLink: { title, slug, url }, parentSlug, divider, disableGutters, onClick } = props;
  if(!slug) return (
    <Link href={url} target="_blank">
      <ListItem
        disableGutters={disableGutters}
        divider={divider}
        classes={{
          root: classes.divider,
        }}
        onClick={onClick}
      >
        {title}
      </ListItem>
    </Link>
  );
  if(!parentSlug) return (
    <Link component={NavLink} to={`/${slug}`}>
      <ListItem
        disableGutters={disableGutters}
        divider={divider}
        classes={{
          root: classes.divider,
        }}
        onClick={onClick}
      >
        {title}
      </ListItem>
    </Link>
  );
  return (
    <Link component={NavLink} to={`/${parentSlug}/${slug}`}>
      <ListItem
        disableGutters={disableGutters}
        divider={divider}
        classes={{
          root: classes.divider,
        }}
        onClick={onClick}
      >
        {title}
      </ListItem>
    </Link>
  );
}

ChapterLink.propTypes = {
  chapterLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles, { withTheme: true })(ChapterLink);

import React from 'react';
import classNames from "classnames";
import parse from 'html-react-parser';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function Standfirst({ content: { standfirst } }) {
  const theme = useTheme()
  return (
    <Typography variant="h3" component="div" style={{ marginBottom: theme.spacing(4) }} className={classNames("standFirst")}>
      { parse(standfirst) }
    </Typography>
  );
}

export default Standfirst;

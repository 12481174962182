import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";
import { withAuthorization } from '../Session';


import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';

import  { BootstrapInput, BootstrapButton } from '../../atoms/Elements';

const styles = (theme) => ({
  wrapper: {
    height: "100vh",
    margin: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    justifyContent:"flex-start",
    [theme.breakpoints.up('md')]: {
      maxWidth: "38%",
      margin:"0 auto",
      marginTop: `-${theme.spacing(8)}px`,
      justifyContent:"center",
    },
  },
  labelText: {
    fontSize:'1.25rem'
  },
  submitWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  returnLink: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
    },
  },
});

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/dashboard');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const { classes, theme } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Box className={classNames(classes.wrapper)}>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h3" component="div" style={{ marginBottom: theme.spacing(4), display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            Change Password
            <Link to="/dashboard">
              <CloseIcon />
            </Link>
          </Typography>
          <FormControl fullWidth>
            <label className={classes.labelText} htmlFor="passwordOne">New Password</label>
            <BootstrapInput
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
            />
          </FormControl>
          <FormControl fullWidth>
            <label className={classes.labelText} htmlFor="passwordTwo">Confirm New Password</label>
            <BootstrapInput
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
            />
          </FormControl>
          <Box className={classes.submitWrapper}>
            <p>
              <BootstrapButton disabled={isInvalid} type="submit">
                Change Password
              </BootstrapButton>
            </p>
            <p>
              <Link className={classes.returnLink} to="/dashboard">Return to dashboard</Link>
            </p>
          </Box>
          <br/>
          {error && <p>{error.message}</p>}
        </form>
      </Box>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(styles, { withTheme: true })(PasswordChangeForm));

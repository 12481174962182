import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import classNames from "classnames";
import PropTypes from "prop-types";
import { AuthUserContext } from '../Session';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import HideOnScroll from '../../atoms/HideOnScroll';
import SiteLogo from '../../atoms/SiteLogo';

const styles = (theme) => ({
  appBar:{
    color: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  header__toolBar: {
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
    "& button": {
      marginRight:`-${theme.spacing(1.7)}px`,
      color:theme.palette.common.white,
    },
    "& > a": {
      marginRight:`-${theme.spacing(1.7)}px`,
      color:theme.palette.common.white,
    }
  },
});

function Header(props) {

  const { classes, onClick } = props;

  const [siteLogoState, setSiteLogoState] = useState({
    loading: true,
    siteLogo: [
      {
        acf: {
          header: {
            site_logo: {
              title: "Site Logo",
              url: "",
            },
            site_logo_alt: {
              title: "Site Logo Alt",
              url: "",
            },
          },
        },
      },
    ],
  });

  const loadSiteLogo = useCallback(() => {
    setSiteLogoState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const siteLogoData = response.data;
        setSiteLogoState({ loading: false, siteLogo: siteLogoData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadSiteLogo();
  }, [loadSiteLogo]);

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar color="primary" elevation={0} classes={{ root: classes.appBar }}>
          <Toolbar className={classNames(classes.header__toolBar)}>

            <SiteLogo siteLogo={siteLogoState.siteLogo[0].acf.header.site_logo_alt} />

            <Box>
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser ?
                    <>
                      <Switch>
                        <Route path={["/password-change", "/profile"]}>
                        </Route>
                        <Route path="/">
                          <Hidden mdUp>
                            <IconButton color="primary" onClick={onClick}>
                              <MenuIcon />
                            </IconButton>
                          </Hidden>
                        </Route>
                      </Switch>
                    </>
                    : ''
                }
              </AuthUserContext.Consumer>

            </Box>

          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Toolbar id="back-to-top-anchor" />
    </>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);

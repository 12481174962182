import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { withAuthentication } from './components/organisms/Session';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalStyles from "./GlobalStyles";

import Header from './components/organisms/Header';
import Dashboard from './components/organisms/Dashboard';
import LogIn from './components/organisms/LogIn';
import PasswordChange from './components/organisms/PasswordChange';
import PasswordForget from './components/organisms/PasswordForget';
import Profile from './components/organisms/Profile';
import Resources from './components/organisms/Resources';
import Footer from './components/organisms/Footer';

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <div className="App">
        <BrowserRouter>
          <Header onClick={handleDrawerToggle} />
          <Switch>
            <Route path="/create-account">
              <LogIn />
              <Footer />
            </Route>
            <Route path="/dashboard">
              <Dashboard mobileOpen={mobileOpen} onClose={handleDrawerToggle} onClick={handleDrawerToggle} />
            </Route>
            <Route path="/log-in">
              <LogIn />
              <Footer />
            </Route>
            <Route path="/password-change">
              <PasswordChange />
              <Footer />
            </Route>
            <Route path="/password-forget">
              <PasswordForget />
              <Footer />
            </Route>
            <Route path="/profile">
              <Profile />
              <Footer />
            </Route>
            <Route path="/:section/:page">
              <Resources mobileOpen={mobileOpen} onClose={handleDrawerToggle} onClick={handleDrawerToggle} />
            </Route>
            <Route path="/:section">
              <Resources mobileOpen={mobileOpen} onClose={handleDrawerToggle} onClick={handleDrawerToggle} />
            </Route>
            <Route path="/">
              <Resources mobileOpen={mobileOpen} onClose={handleDrawerToggle} onClick={handleDrawerToggle} />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default withAuthentication(App);

import React, { Component } from 'react';
import { withFirebase } from '../../organisms/Firebase';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import LogOutLink from '../../atoms/LogOutLink';

import { withStyles } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

const styles = (theme) => ({
  dashboardLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
})

class ProfileSnapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: ''
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.props.firebase.user(authUser.uid).once('value', (snapshot) => {
          if (snapshot.exists()){
            const data = snapshot.val();
            this.setState({
              firstName: data.firstName,
            })
          }
        });
      }
    })
  }

  componentWillUnmount() {
    this.listener();
    this.props.firebase.user().off();
  }

  render() {
    const { theme, onClick, classes } = this.props;

    return (
      <div style={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
        <Paper square elevation={0} style={{ backgroundColor: "rgba(3, 23, 46, 0.04)", padding: theme.spacing(1), textAlign: 'left', marginBottom: theme.spacing(3) }}>
            <Switch>
              <Route path="/dashboard">
                <Link onClick={onClick} className={classes.dashboardLink} to="/">
                  Resources
                </Link>
              </Route>
              <Route path="/">
                <Link onClick={onClick} className={classes.dashboardLink} to="/dashboard">
                  Dashboard
                </Link>
              </Route>
            </Switch>
            <Divider style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
              }}
            />
            <Link onClick={onClick} className={classes.dashboardLink} to="/profile">
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <AccountCircleIcon style={{ marginRight: theme.spacing(0.5) }}/>
                {this.state.firstName}
              </div>
            </Link>
          <Divider style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            }}
          />
          <LogOutLink onClick={onClick} />
        </Paper>
        <a target="_blank" rel="noreferrer" href="https://instagram.com/stepscourse"><FaInstagram /></a>
        &nbsp;&nbsp;&nbsp;
        <a target="_blank" rel="noreferrer" href="https://facebook.com/thestepscourse"><FaFacebook /></a>
        &nbsp;&nbsp;&nbsp;
        <a target="_blank" rel="noreferrer" href="https://twitter.com/stepscourse"><FaTwitter /></a>
      </div>
    );
  }
}

export default withFirebase(withStyles(styles, { withTheme: true })(ProfileSnapshot))

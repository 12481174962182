import {
  Button,
  fade,
  withStyles,
} from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #03172E',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    marginBottom: theme.spacing(2.5)
  },
}))(InputBase);

const BootstrapButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginTop: theme.spacing(1.5),
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#03172E',
    borderColor: '#03172E',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: '#03172E',
      borderColor: '#03172E',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#03172E',
      borderColor: '#03172E',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '&:disabled':{
      backgroundColor: 'transparent',
      color: '#03172E'
    }
  },
}))(Button);

export { BootstrapInput, BootstrapButton };

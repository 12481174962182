import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiBox from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';

import H1 from '../../atoms/H1';
import H2 from '../../atoms/H2';
import HRule from '../../atoms/HRule';
import Paragraph from '../../atoms/Paragraph';
import ListTwat from '../../atoms/List';
import NumberedListTwat from '../../atoms/NumberedList';
import Download from '../../atoms/Download';

const Accordion = withStyles( theme => ({
  root: {
    borderTop: `1px solid rgba(3, 23, 46, 0.4)`,
    boxShadow: 'none',
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles( theme => ({
  root: {
    paddingLeft:0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: `${theme.spacing(2)}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.primary.main,
    '&$expanded': {
      transform: 'rotate(45deg)',
    },
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft:0,
    display: "block",
  },
}))(MuiAccordionDetails);

const Box = withStyles((theme) => ({
  root: {
    width: "100%",
    height: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(8),
    },
  },
}))(MuiBox);

function AccordionFeature({ content, onChange }) {
  const contents = content.accordion_components.map(accordionComponent => {
    const accordionComponentType = accordionComponent.acf_fc_layout;
    if(accordionComponentType === "h1") return <H1 key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "h2") return <H2 key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "hrule") return <HRule key={accordionComponent.id} />;
    if(accordionComponentType === "paragraph") return <Paragraph key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "list") return <ListTwat key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "numbered_list") return <NumberedListTwat key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "download") return <Download key={accordionComponent.id} content={accordionComponent} />;
    if(accordionComponentType === "spacer") return <Box key={accordionComponent.id} />;
    return null;
  });

  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion square expanded={expanded === content.id} onChange={handleChange(content.id)}>
      <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant="h4" style={{ paddingTop:"4px" }}>
          {content.question}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {contents}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionFeature

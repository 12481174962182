import React from 'react';
import Divider from '@material-ui/core/Divider';

import { useTheme } from "@material-ui/styles"

function HRule() {
  const theme = useTheme()
  return (
    <Divider style={{ margin: `${theme.spacing(4)}px 0` }} />
  )
}

export default HRule

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { withAuthorization } from '../Session';
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import SiteLogo from '../../atoms/SiteLogo';
import ChaptersLinkList from '../../molecules/ChaptersLinkList';
import H1 from '../../atoms/H1';
import H2 from '../../atoms/H2';
import HRule from '../../atoms/HRule';
import Standfirst from '../../atoms/Standfirst';
import Paragraph from '../../atoms/Paragraph';
import ListTwat from '../../atoms/List';
import NumberedListTwat from '../../atoms/NumberedList';
import Pullout from '../../atoms/Pullout';
import Quote from '../../atoms/Quote';
import Table from '../../atoms/Table';
import Video from '../../atoms/Video';
import Download from '../../atoms/Download';
import AccordionFeature from '../../atoms/Accordion';
import Footer from '../../organisms/Footer';
import ProfileSnapshot from '../../molecules/ProfileSnapshot';

const drawerWidth = 240;
const drawerWidthSm = 360;
const drawerWidthMd = 320;

const styles = (theme) => ({
  spacer: {
    width: "100%",
    height: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(8),
    },
  },
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidthMd}px)`,
      marginLeft: drawerWidthMd,
    },
  },
  drawer__toolbar: {
    justifyContent: "flex-end",
    [theme.breakpoints.up('md')]: {
      justifyContent: "space-between",
    },

    "& button": {
      marginRight:`-${theme.spacing(2)}px`,
      color:theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
  },
  drawer__items: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 64px)',
  },
  content: {
    minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(12)}px ${theme.spacing(4)}px ${theme.spacing(12)}px`,
    },
  },
  profileFix: {
    marginBottom: "-24px",
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  }
})

function Resources(props) {

  const { classes, mobileOpen, onClose, onClick } = props;

  const [siteLogoState, setSiteLogoState] = useState({
    loading: true,
    siteLogo: [
      {
        acf: {
          header: {
            site_logo: {
              title: "Site Logo",
              url: "",
            },
            site_logo_alt: {
              title: "Site Logo Alt",
              url: "",
            },
          },
        },
      },
    ],
  });

  const loadSiteLogo = useCallback(() => {
    setSiteLogoState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const siteLogoData = response.data;
        setSiteLogoState({ loading: false, siteLogo: siteLogoData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadSiteLogo();
  }, [loadSiteLogo]);

  const [chaptersState, setChaptersState] = useState({
    loading: true,
    chapters: {
      items: [
        {
          "ID": 0,
          "title": "",
          "url": "",
        },
      ],
    },
  });

  const loadChapters = useCallback(() => {
    setChaptersState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/menus/v1/menus/steps-resources-chapters`;
    axios.get(apiUrl)
      .then((response) => {
        const chaptersData = response.data;
        setChaptersState({ loading: false, chapters: chaptersData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadChapters();
  }, [loadChapters]);

  let { section, page } = useParams()
  let cleanSection = section
  let cleanSlug = page
  let blue = ""
  let root = false

  if(section){
    cleanSection = section.replace(/-/g , "_")
  }

  if(!page){
    cleanSlug = section
    blue = "blue"
  }

  if(!section){
    section = "overview"
    cleanSection = "overview"
    cleanSlug = "overview"
    root = true
  }

  const [contentState, setContentState] = useState({
    loading: true,
    content: [
      {
        title_page: "false",
        acf: {
          components:[],
        },
      },
    ],
  });
  const loadContent = useCallback(() => {
    setContentState(prevState => {
      return {...prevState, loading: true}
    })
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/${cleanSection}?slug=${cleanSlug}`;
    axios.get(apiUrl)
      .then((response) => {
        const contentData = response.data;
        setContentState({ loading: false, content: contentData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, [cleanSection, cleanSlug]);

  useEffect(() =>{
    loadContent();
  }, [loadContent]);

  const contents = contentState.content[0].acf.components.map(resourcesComponent => {
    const resourcesComponentType = resourcesComponent.acf_fc_layout;
    if(resourcesComponentType === "h1") return <H1 key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "h2") return <H2 key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "hrule") return <HRule key={resourcesComponent.id} />;
    if(resourcesComponentType === "standfirst") return <Standfirst key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "paragraph") return <Paragraph key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "list") return <ListTwat key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "numbered_list") return <NumberedListTwat key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "pullout") return <Pullout key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "quote") return <Quote key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "table") return <Table key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "video") return <Video key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "download") return <Download key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "accordion") return <AccordionFeature key={resourcesComponent.id} content={resourcesComponent} />;
    if(resourcesComponentType === "spacer") return <Box key={resourcesComponent.id} className={classNames(classes.spacer,resourcesComponent.id)} />;
    return null;
  });

  const drawer = (
    <Box style={{ height:'100%' }}>
      <Toolbar className={classes.drawer__toolbar}>
        <Hidden smDown>
          <SiteLogo siteLogo={siteLogoState.siteLogo[0].acf.header.site_logo} noLink />
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Typography variant="body1" component="div" className={classes.drawer__items}>
        <Box>
          <ChaptersLinkList chapterLinks={chaptersState.chapters.items} current={section} root={root} onClick={onClick} />
        </Box>
        <div className={classes.profileFix}>
          <ProfileSnapshot onClick={onClick} />
          <br/>
        </div>
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <nav className={classes.drawer} aria-label="Chapters">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer classes={{ paper: classes.drawerPaper,}} variant="permanent" open>
            { drawer }
          </Drawer>
        </Hidden>
      </nav>

      <main className={`${blue}`}>
        <Box className={classes.content}>
          <Box className={classes.toolbar} />
          <div>
            { contents }
          </div>
        </Box>
        <Footer />
      </main>
    </Box>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(styles, { withTheme: true })(Resources))

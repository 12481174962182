import React from 'react';
import { withFirebase } from '../../organisms/Firebase';

import Typography from '@material-ui/core/Typography';

const LogOutLink = ({ firebase }) => (
  <Typography variant="body1" onClick={firebase.doSignOut} className="logout">
    Log out
  </Typography>
);

export default withFirebase(LogOutLink);

import React from 'react';
import classNames from "classnames";
import { NavLink } from 'react-router-dom';

import { withStyles } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';

import ChapterLink from '../../atoms/ChapterLink';

const Accordion = withStyles( theme => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles( theme => ({
  root: {
    paddingLeft:0,
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  expanded: {},
  content: {
    margin: `${theme.spacing(0)}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(0)}px 0`,
    },
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    paddingLeft:0,
    "& ul":{
      marginTop:0,
    },
  },
}))(MuiAccordionDetails);

function ChaptersLinkList(props) {

  const {
    root,
    current,
    chapterLinks,
    divider,
    disableGutters,
    onClick } = props;

  const chapterLinkList = chapterLinks.map(chapterLink => {
    if(chapterLink.child_items !== undefined) {
      return (
        <Accordion
          square
          expanded={chapterLink.title.replace(/\s+/g, '-').toLowerCase() === current}
          key={chapterLink.ID}
        >
          <Link
            exact
            component={NavLink}
            to={`/${chapterLink.slug}`}
            className={classNames(root ? "root" : "", chapterLink.slug)}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header">
                {chapterLink.title}
            </AccordionSummary>
          </Link>
          <AccordionDetails>
            <List>
              {chapterLink.child_items.map(chapterLinkChild => {
                return (
                  <ChapterLink
                  key={chapterLinkChild.ID}
                    chapterLink={chapterLinkChild}
                    parentSlug={chapterLink.title.replace(/\s+/g, '-').toLowerCase()}
                    disableGutters={disableGutters}
                    divider={divider}
                    onClick={onClick} />
                )
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <ChapterLink
          key={chapterLink.ID}
          chapterLink={chapterLink}
          disableGutters={disableGutters}
          divider={divider}
          onClick={onClick} />
      );
    }
  });
  return chapterLinkList
}

export default ChaptersLinkList

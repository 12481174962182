import React, { useEffect, useState } from 'react';

import { withStyles } from "@material-ui/core";
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

const styles = (theme) => ({
  root: {
    border: "1px solid #03172E",
    padding: "10px 12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  select: {

  },
});

const options = [
  {label: "", value: ""},
  {label: "United Kingdom", value: "United Kingdom"},
  {label: "Aaland Islands", value: "Aaland Islands"},
  {label: "Afghanistan", value: "Afghanistan"},
  {label: "Albania", value: "Albania"},
  {label: "Algeria", value: "Algeria"},
  {label: "American Samoa", value: "American Samoa"},
  {label: "Andorra", value: "Andorra"},
  {label: "Angola", value: "Angola"},
  {label: "Anguilla", value: "Anguilla"},
  {label: "Antarctica", value: "Antarctica"},
  {label: "Antigua And Barbuda", value: "Antigua And Barbuda"},
  {label: "Argentina", value: "Argentina"},
  {label: "Armenia", value: "Armenia"},
  {label: "Aruba", value: "Aruba"},
  {label: "Australia", value: "Australia"},
  {label: "Austria", value: "Austria"},
  {label: "Azerbaijan", value: "Azerbaijan"},
  {label: "Bahamas", value: "Bahamas"},
  {label: "Bahrain", value: "Bahrain"},
  {label: "Bangladesh", value: "Bangladesh"},
  {label: "Barbados", value: "Barbados"},
  {label: "Belarus", value: "Belarus"},
  {label: "Belgium", value: "Belgium"},
  {label: "Belize", value: "Belize"},
  {label: "Benin", value: "Benin"},
  {label: "Bermuda", value: "Bermuda"},
  {label: "Bhutan", value: "Bhutan"},
  {label: "Bolivia", value: "Bolivia"},
  {label: "Bonaire, Saint Eustatius and Saba", value: "Bonaire, Saint Eustatius and Saba"},
  {label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina"},
  {label: "Botswana", value: "Botswana"},
  {label: "Bouvet Island", value: "Bouvet Island"},
  {label: "Brazil", value: "Brazil"},
  {label: "British Indian Ocean Territory", value: "British Indian Ocean Territory"},
  {label: "Brunei Darussalam", value: "Brunei Darussalam"},
  {label: "Bulgaria", value: "Bulgaria"},
  {label: "Burkina Faso", value: "Burkina Faso"},
  {label: "Burundi", value: "Burundi"},
  {label: "Cambodia", value: "Cambodia"},
  {label: "Cameroon", value: "Cameroon"},
  {label: "Canada", value: "Canada"},
  {label: "Cape Verde", value: "Cape Verde"},
  {label: "Cayman Islands", value: "Cayman Islands"},
  {label: "Central African Republic", value: "Central African Republic"},
  {label: "Chad", value: "Chad"},
  {label: "Chile", value: "Chile"},
  {label: "China", value: "China"},
  {label: "Christmas Island", value: "Christmas Island"},
  {label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands"},
  {label: "Colombia", value: "Colombia"},
  {label: "Comoros", value: "Comoros"},
  {label: "Congo", value: "Congo"},
  {label: "Cook Islands", value: "Cook Islands"},
  {label: "Costa Rica", value: "Costa Rica"},
  {label: "Cote D'Ivoire", value: "Cote D'Ivoire"},
  {label: "Croatia", value: "Croatia"},
  {label: "Cuba", value: "Cuba"},
  {label: "Curacao", value: "Curacao"},
  {label: "Cyprus", value: "Cyprus"},
  {label: "Czech Republic", value: "Czech Republic"},
  {label: "Democratic Republic of the Congo", value: "Democratic Republic of the Congo"},
  {label: "Denmark", value: "Denmark"},
  {label: "Djibouti", value: "Djibouti"},
  {label: "Dominica", value: "Dominica"},
  {label: "Dominican Republic", value: "Dominican Republic"},
  {label: "Ecuador", value: "Ecuador"},
  {label: "Egypt", value: "Egypt"},
  {label: "El Salvador", value: "El Salvador"},
  {label: "Equatorial Guinea", value: "Equatorial Guinea"},
  {label: "Eritrea", value: "Eritrea"},
  {label: "Estonia", value: "Estonia"},
  {label: "Ethiopia", value: "Ethiopia"},
  {label: "Falkland Islands", value: "Falkland Islands"},
  {label: "Faroe Islands", value: "Faroe Islands"},
  {label: "Fiji", value: "Fiji"},
  {label: "Finland", value: "Finland"},
  {label: "France", value: "France"},
  {label: "French Guiana", value: "French Guiana"},
  {label: "French Polynesia", value: "French Polynesia"},
  {label: "French Southern Territories", value: "French Southern Territories"},
  {label: "Gabon", value: "Gabon"},
  {label: "Gambia", value: "Gambia"},
  {label: "Georgia", value: "Georgia"},
  {label: "Germany", value: "Germany"},
  {label: "Ghana", value: "Ghana"},
  {label: "Gibraltar", value: "Gibraltar"},
  {label: "Greece", value: "Greece"},
  {label: "Greenland", value: "Greenland"},
  {label: "Grenada", value: "Grenada"},
  {label: "Guadeloupe", value: "Guadeloupe"},
  {label: "Guam", value: "Guam"},
  {label: "Guatemala", value: "Guatemala"},
  {label: "Guernsey", value: "Guernsey"},
  {label: "Guinea", value: "Guinea"},
  {label: "Guinea-Bissau", value: "Guinea-Bissau"},
  {label: "Guyana", value: "Guyana"},
  {label: "Haiti", value: "Haiti"},
  {label: "Heard and Mc Donald Islands", value: "Heard and Mc Donald Islands"},
  {label: "Honduras", value: "Honduras"},
  {label: "Hong Kong", value: "Hong Kong"},
  {label: "Hungary", value: "Hungary"},
  {label: "Iceland", value: "Iceland"},
  {label: "India", value: "India"},
  {label: "Indonesia", value: "Indonesia"},
  {label: "Iran", value: "Iran"},
  {label: "Iraq", value: "Iraq"},
  {label: "Ireland", value: "Ireland"},
  {label: "Isle of Man", value: "Isle of Man"},
  {label: "Israel", value: "Israel"},
  {label: "Italy", value: "Italy"},
  {label: "Jamaica", value: "Jamaica"},
  {label: "Japan", value: "Japan"},
  {label: "Jersey  (Channel Islands)", value: "Jersey  (Channel Islands)"},
  {label: "Jordan", value: "Jordan"},
  {label: "Kazakhstan", value: "Kazakhstan"},
  {label: "Kenya", value: "Kenya"},
  {label: "Kiribati", value: "Kiribati"},
  {label: "Kuwait", value: "Kuwait"},
  {label: "Kyrgyzstan", value: "Kyrgyzstan"},
  {label: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic"},
  {label: "Latvia", value: "Latvia"},
  {label: "Lebanon", value: "Lebanon"},
  {label: "Lesotho", value: "Lesotho"},
  {label: "Liberia", value: "Liberia"},
  {label: "Libya", value: "Libya"},
  {label: "Liechtenstein", value: "Liechtenstein"},
  {label: "Lithuania", value: "Lithuania"},
  {label: "Luxembourg", value: "Luxembourg"},
  {label: "Macau", value: "Macau"},
  {label: "Macedonia", value: "Macedonia"},
  {label: "Madagascar", value: "Madagascar"},
  {label: "Malawi", value: "Malawi"},
  {label: "Malaysia", value: "Malaysia"},
  {label: "Maldives", value: "Maldives"},
  {label: "Mali", value: "Mali"},
  {label: "Malta", value: "Malta"},
  {label: "Marshall Islands", value: "Marshall Islands"},
  {label: "Martinique", value: "Martinique"},
  {label: "Mauritania", value: "Mauritania"},
  {label: "Mauritius", value: "Mauritius"},
  {label: "Mayotte", value: "Mayotte"},
  {label: "Mexico", value: "Mexico"},
  {label: "Micronesia, Federated States of", value: "Micronesia, Federated States of"},
  {label: "Moldova, Republic of", value: "Moldova, Republic of"},
  {label: "Monaco", value: "Monaco"},
  {label: "Mongolia", value: "Mongolia"},
  {label: "Montenegro", value: "Montenegro"},
  {label: "Montserrat", value: "Montserrat"},
  {label: "Morocco", value: "Morocco"},
  {label: "Mozambique", value: "Mozambique"},
  {label: "Myanmar", value: "Myanmar"},
  {label: "Namibia", value: "Namibia"},
  {label: "Nauru", value: "Nauru"},
  {label: "Nepal", value: "Nepal"},
  {label: "Netherlands", value: "Netherlands"},
  {label: "Netherlands Antilles", value: "Netherlands Antilles"},
  {label: "New Caledonia", value: "New Caledonia"},
  {label: "New Zealand", value: "New Zealand"},
  {label: "Nicaragua", value: "Nicaragua"},
  {label: "Niger", value: "Niger"},
  {label: "Nigeria", value: "Nigeria"},
  {label: "Niue", value: "Niue"},
  {label: "Norfolk Island", value: "Norfolk Island"},
  {label: "North Korea", value: "North Korea"},
  {label: "Northern Mariana Islands", value: "Northern Mariana Islands"},
  {label: "Norway", value: "Norway"},
  {label: "Oman", value: "Oman"},
  {label: "Pakistan", value: "Pakistan"},
  {label: "Palau", value: "Palau"},
  {label: "Palestine", value: "Palestine"},
  {label: "Panama", value: "Panama"},
  {label: "Papua New Guinea", value: "Papua New Guinea"},
  {label: "Paraguay", value: "Paraguay"},
  {label: "Peru", value: "Peru"},
  {label: "Philippines", value: "Philippines"},
  {label: "Pitcairn", value: "Pitcairn"},
  {label: "Poland", value: "Poland"},
  {label: "Portugal", value: "Portugal"},
  {label: "Puerto Rico", value: "Puerto Rico"},
  {label: "Qatar", value: "Qatar"},
  {label: "Republic of Kosovo", value: "Republic of Kosovo"},
  {label: "Reunion", value: "Reunion"},
  {label: "Romania", value: "Romania"},
  {label: "Russia", value: "Russia"},
  {label: "Rwanda", value: "Rwanda"},
  {label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis"},
  {label: "Saint Lucia", value: "Saint Lucia"},
  {label: "Saint Martin", value: "Saint Martin"},
  {label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines"},
  {label: "Samoa (Independent)", value: "Samoa (Independent)"},
  {label: "San Marino", value: "San Marino"},
  {label: "Sao Tome and Principe", value: "Sao Tome and Principe"},
  {label: "Saudi Arabia", value: "Saudi Arabia"},
  {label: "Senegal", value: "Senegal"},
  {label: "Serbia", value: "Serbia"},
  {label: "Seychelles", value: "Seychelles"},
  {label: "Sierra Leone", value: "Sierra Leone"},
  {label: "Singapore", value: "Singapore"},
  {label: "Sint Maarten", value: "Sint Maarten"},
  {label: "Slovakia", value: "Slovakia"},
  {label: "Slovenia", value: "Slovenia"},
  {label: "Solomon Islands", value: "Solomon Islands"},
  {label: "Somalia", value: "Somalia"},
  {label: "South Africa", value: "South Africa"},
  {label: "South Georgia and the South Sandwich Islands", value: "South Georgia and the South Sandwich Islands"},
  {label: "South Korea", value: "South Korea"},
  {label: "South Sudan", value: "South Sudan"},
  {label: "Spain", value: "Spain"},
  {label: "Sri Lanka", value: "Sri Lanka"},
  {label: "St. Helena", value: "St. Helena"},
  {label: "St. Pierre and Miquelon", value: "St. Pierre and Miquelon"},
  {label: "Sudan", value: "Sudan"},
  {label: "Suriname", value: "Suriname"},
  {label: "Svalbard and Jan Mayen Islands", value: "Svalbard and Jan Mayen Islands"},
  {label: "Swaziland", value: "Swaziland"},
  {label: "Sweden", value: "Sweden"},
  {label: "Switzerland", value: "Switzerland"},
  {label: "Syria", value: "Syria"},
  {label: "Taiwan", value: "Taiwan"},
  {label: "Tajikistan", value: "Tajikistan"},
  {label: "Tanzania", value: "Tanzania"},
  {label: "Thailand", value: "Thailand"},
  {label: "Timor-Leste", value: "Timor-Leste"},
  {label: "Togo", value: "Togo"},
  {label: "Tokelau", value: "Tokelau"},
  {label: "Tonga", value: "Tonga"},
  {label: "Trinidad and Tobago", value: "Trinidad and Tobago"},
  {label: "Tunisia", value: "Tunisia"},
  {label: "Turkey", value: "Turkey"},
  {label: "Turkmenistan", value: "Turkmenistan"},
  {label: "Turks &amp; Caicos Islands", value: "Turks &amp; Caicos Islands"},
  {label: "Turks and Caicos Islands", value: "Turks and Caicos Islands"},
  {label: "Tuvalu", value: "Tuvalu"},
  {label: "Uganda", value: "Uganda"},
  {label: "Ukraine", value: "Ukraine"},
  {label: "United Arab Emirates", value: "United Arab Emirates"},
  {label: "United States of America", value: "United States of America"},
  {label: "Uruguay", value: "Uruguay"},
  {label: "USA Minor Outlying Islands", value: "USA Minor Outlying Islands"},
  {label: "Uzbekistan", value: "Uzbekistan"},
  {label: "Vanuatu", value: "Vanuatu"},
  {label: "Vatican City State (Holy See)", value: "Vatican City State (Holy See)"},
  {label: "Venezuela", value: "Venezuela"},
  {label: "Vietnam", value: "Vietnam"},
  {label: "Virgin Islands (British)", value: "Virgin Islands (British)"},
  {label: "Virgin Islands (U.S.)", value: "Virgin Islands (U.S.)"},
  {label: "Wallis and Futuna Islands", value: "Wallis and Futuna Islands"},
  {label: "Western Sahara", value: "Western Sahara"},
  {label: "Yemen", value: "Yemen"},
  {label: "Zambia", value: "Zambia"},
  {label: "Zimbabwe", value: "Zimbabwe"},
];

function CountrySelect({ classes, value, onFocus, onChange, onBlur }) {

    const [localValue, setLocalValue] = useState(value ?? '');  // we want to keep value locally

    useEffect(() => {
      setLocalValue(value ?? '');
    }, [value]); // we want to update local value on prop value change

    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setLocalValue(value);
        if (onChange) {
            onChange(value);
        }
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e.target.value);
        }
    };

    return (
        <Select
          value={localValue}      // we want to work in controlled mode
          onFocus={handleFocus}
          onChange={handleChange} // we want to work in controlled mode
          onBlur={handleBlur}
          classes={{root: classes.root, select: classes.select}}
          disableUnderline
        >
          {options?.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label ?? option.value}
                </MenuItem>
              );
          })}
        </Select>
    );
};

export default withStyles(styles, { withTheme: true })(CountrySelect);

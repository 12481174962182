import React from 'react';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function Quote({ content: { quote, name, title } }) {
  const theme = useTheme()
  return (
    <Typography variant="body1" style={{ margin: `${theme.spacing(4)}px 0`, padding: `0 ${theme.spacing(6)}px` }}>
      "{ quote }"
      <br/><br/>
      { name }
      <br/>
      { title }
    </Typography>
  );
}

export default Quote;

import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink, Route } from 'react-router-dom';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';

import LogInForm from '../../molecules/LogInForm';
import CreateAccountForm from '../../molecules/CreateAccountForm';

const styles = (theme) => ({
  wrapper: {
    height: "100%",
    margin: `0 ${theme.spacing(2)}px`,
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    justifyContent:"flex-start",
    [theme.breakpoints.up('md')]: {
      minHeight: "100vh",
      maxWidth: "36%",
      margin:"0 auto",
      marginTop: `-${theme.spacing(4)}px`,
      justifyContent:"center",
    },
  },
  nav__wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    '& a': {
      fontSize:'1.25rem',
      textDecoration: 'none',
      color: '#000',
      width: '50%',
      paddingTop: '1.5rem',
      paddingBottom: 'calc(1.5rem + 2px)',
      borderBottom: '1px solid #CCC',
      '&.right': {
        textAlign: 'right',
      },
      '&.active': {
        borderBottom: '3px solid #03172E',
        paddingBottom: '1.5rem',
        textDecoration: 'none',
      },
    },
  }
});

function LogIn({classes}) {
  return (
    <Box className={classNames(classes.wrapper)}>
        <Box className={classNames(classes.nav__wrapper)}>
          <NavLink to="/log-in">Log in</NavLink>
          <NavLink className="right" to="/create-account">Create account</NavLink>
        </Box>
        <Route path="/log-in" component={LogInForm} />
        <Route path="/create-account">
          <CreateAccountForm />
        </Route>
    </Box>
  )
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LogIn);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";
import { withFirebase } from '../Firebase';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import  { BootstrapInput, BootstrapButton } from '../../atoms/Elements';

const styles = (theme) => ({
  wrapper: {
    height: "100vh",
    margin: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    justifyContent:"flex-start",
    [theme.breakpoints.up('md')]: {
      maxWidth: "36%",
      margin:"0 auto",
      marginTop: `-${theme.spacing(8)}px`,
      justifyContent:"center",
    },
  },
  form: {
    width: "100%",
  },
  labelText: {
    fontSize:'1.25rem'
  },
  submitWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  }
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { classes } = this.props;

    const isInvalid = email === '';

    return (
      <Box className={classNames(classes.wrapper)}>
        <form onSubmit={this.onSubmit} className={classNames(classes.form)}>
          <FormControl fullWidth="true">
            <label className={classes.labelText} for="email">Email</label>
            <BootstrapInput
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
            />
          </FormControl>
          <Box className={classes.submitWrapper}>
            <BootstrapButton disabled={isInvalid} type="submit" style={ {marginBottom:"32px" }}>
              Reset My Password
            </BootstrapButton>
            <p>
              <Link to="/log-in">Return to Login</Link>
            </p>
          </Box>
          <br/>
          {error && <p>{error.message}</p>}
        </form>
      </Box>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to="/password-forget">Forgot Password?</Link>
  </p>
);

const PasswordForgetForm = withFirebase(withStyles(styles, { withTheme: true })(PasswordForgetFormBase));

export default PasswordForgetForm;

export { PasswordForgetForm, PasswordForgetLink };

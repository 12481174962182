import React from 'react';
import classNames from "classnames";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import StepsButton from '../../atoms/StepsButton';

function Download({ content: { attachment_or_email, button_text, label, attachment, email } }) {

  let link = ""
  if(attachment_or_email === "Email"){
    link = "mailto:" + email
  }
  if(attachment_or_email === "Attachment"){
    link = attachment
  }


  return (
    <>
      <Grid container style={{alignItems:"flex-start",justifyContent:"space-between", margin: "24px 0", "& a": { marginTop: "40px" }}}>
        <Grid item xs={6}>
        <Typography variant="body1">
          { label }
        </Typography>
        </Grid>
        <Grid>
          <StepsButton
            buttonType="external"
            buttonText={button_text}
            buttonLink={link}
            dark
            white
            className={classNames("downloadButton")}
          />
        </Grid>
      </Grid>

    </>
  );
}

export default Download;

import React from 'react';

import { useTheme } from "@material-ui/styles"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function ListTwat({ content: { list_item } }) {
  const theme = useTheme()
  const listItems = list_item.map(list_item => {
    return (
      <ListItem key={list_item.id} disableGutters style={{ padding:0, alignItems: "baseline", }}>
        <ListItemIcon style={{ minWidth: theme.spacing(2), fontSize: theme.typography.body1.fontSize, color: theme.palette.primary.main }}>
          -
        </ListItemIcon>
        <ListItemText>
          {list_item.list_item}
        </ListItemText>
      </ListItem>
    );
  })

  return (
    <List style={{ marginBottom: theme.spacing(2) }}>
      { listItems }
    </List>
  );
}

export default ListTwat;

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../../organisms/Firebase';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import  { BootstrapInput, BootstrapButton } from '../../atoms/Elements';
import  CountrySelect from '../../atoms/CountrySelect';

const Accordion = withStyles( theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    boxShadow: 'none',
    backgroundColor: 'rgba(3, 23, 46, 0.04)',
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles( theme => ({
  root: {
    paddingLeft:0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: `${theme.spacing(2)}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
    '&$expanded': {
      transform: 'rotate(45deg)',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft:0,
    display: "block",
  },
}))(MuiAccordionDetails);

const styles = (theme) => ({
  labelText: {
    fontSize:'1.25rem'
  },
  submitWrapper: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  returnLink: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  checkBox: {
    color: "BLACK",
  },
  checkBoxLabel: {
    fontSize: "10px",
  },
});

const INITIAL_STATE = {
  uid: '',
  email:'',
  firstName: '',
  secondName: '',
  churchOrOrganisation: '',
  country: '',
  stepsCourseMailingList: false,
  stepsResroucesMailingList: false,
  stepsPrayerMailingList: false,
  dateOfIntroduction: '',
  dateOfWeekOne: '',
  dateOfWeekFive: '',
  error: null,
};

class ProfileFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.setState({uid: authUser.uid, email: authUser.email})
        this.props.firebase.user(authUser.uid).on('value', (snapshot) => {
          if (snapshot.exists()){
            const data = snapshot.val();
            this.setState({
              firstName: data.firstName,
              secondName: data.secondName,
              churchOrOrganisation: data.churchOrOrganisation,
              country: data.country,
              stepsCourseMailingList: data.stepsCourseMailingList,
              stepsResroucesMailingList: data.stepsResroucesMailingList,
              stepsPrayerMailingList: data.stepsPrayerMailingList,
              dateOfIntroduction: data.dateOfIntroduction,
              dateOfWeekOne: data.dateOfWeekOne,
              dateOfWeekFive: data.dateOfWeekFive
            })
          }
        });
      }
    })
  }

  componentWillUnmount() {
    this.listener();
    this.props.firebase.user().off();
  }

  onSubmit = event => {
    const {
      uid,
      email,
      firstName,
      secondName,
      churchOrOrganisation,
      country,
      stepsCourseMailingList,
      stepsResroucesMailingList,
      stepsPrayerMailingList,
      dateOfIntroduction,
      dateOfWeekOne,
      dateOfWeekFive
    } = this.state;

    this.props.firebase.user(uid).update({
      email,
      firstName,
      secondName,
      churchOrOrganisation,
      country,
      stepsCourseMailingList,
      stepsResroucesMailingList,
      stepsPrayerMailingList,
      dateOfIntroduction,
      dateOfWeekOne,
      dateOfWeekFive,
    })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/dashboard');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSelectChange = value => {
    this.setState({ country: value });
  };


  onCheckChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  };

  render() {
    const { classes, theme } = this.props;
    const {
      uid,
      firstName,
      secondName,
      churchOrOrganisation,
      country,
      stepsCourseMailingList,
      stepsResroucesMailingList,
      stepsPrayerMailingList,
      dateOfIntroduction,
      dateOfWeekOne,
      dateOfWeekFive,
      error,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Typography variant="h3" component="div" style={{ marginBottom: theme.spacing(4), display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          Update Profile
          <Link to="/dashboard">
            <CloseIcon />
          </Link>
        </Typography>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="firstName">First Name</label>
          <BootstrapInput
            name="firstName"
            value={firstName}
            onChange={this.onChange}
            type="text"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="secondName">Second Name</label>
          <BootstrapInput
            name="secondName"
            value={secondName}
            onChange={this.onChange}
            type="text"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="churchOrOrganisation">Church / Organisation</label>
          <BootstrapInput
            name="churchOrOrganisation"
            value={churchOrOrganisation}
            onChange={this.onChange}
            type="text"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="country">Country</label>
          <CountrySelect name="country" value={country} onChange={this.onSelectChange} />
        </FormControl>

        <Typography variant="h6" style={{ marginTop: theme.spacing(4) }}>I'd like to recieve emails about:</Typography>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Checkbox
              className={classes.checkBox}
              color="secondary"
              name="stepsCourseMailingList"
              value={stepsCourseMailingList}
              checked={stepsCourseMailingList}
              onChange={this.onCheckChange}
            />}
          label="STEPS course news and updates"
        />
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Checkbox
              className={classes.checkBox}
              name="stepsResroucesMailingList"
              color="secondary"
              value={stepsResroucesMailingList}
              checked={stepsResroucesMailingList}
              onChange={this.onCheckChange}
            />}
          label="How I can run STEPS in my own community"
        />
        <FormControlLabel
          className={classes.checkBoxLabel}
          style={{ marginBottom: theme.spacing(3) }}
          control={
            <Checkbox
              className={classes.checkBox}
              name="stepsPrayerMailingList"
              color="secondary"
              value={stepsPrayerMailingList}
              checked={stepsPrayerMailingList}
              onChange={this.onCheckChange}
            />}
          label="How I can pray for the STEPS course"
        />

        <Accordion square>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography variant="h4">
              Your course dates
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <FormControl fullWidth>
              <label className={classes.labelText} htmlFor="dateOfIntroduction">Date Of Introduction</label>
              <BootstrapInput
                name="dateOfIntroduction"
                value={dateOfIntroduction}
                onChange={this.onChange}
                type="date"
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <label className={classes.labelText} htmlFor="dateOfWeekOne">Date Of Week One</label>
              <BootstrapInput
                name="dateOfWeekOne"
                value={dateOfWeekOne}
                onChange={this.onChange}
                type="date"
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <label className={classes.labelText} htmlFor="dateOfWeekFive">Date Of Week Five</label>
              <BootstrapInput
                name="dateOfWeekFive"
                value={dateOfWeekFive}
                onChange={this.onChange}
                type="date"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Box className={classes.submitWrapper}>
          <p>
            <BootstrapButton disabled={!uid} type="submit">
              Update Profile
            </BootstrapButton>
          </p>
          <p>
            <Link className={classes.returnLink} to="/dashboard">Return to dashboard</Link>
          </p>
        </Box>
        <br/>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const ProfileForm = withRouter(withFirebase(withStyles(styles, { withTheme: true })(ProfileFormBase)));

export default ProfileForm;

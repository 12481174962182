import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  video__wrapperOuter: {
    margin: `${theme.spacing(2)}px 0`,
  },
  video__wrapperInner: {
    padding:"56.25% 0 0 0",
    position:"relative",
  },
  video__item: {
    position:"absolute",
    top:"0",
    left:"0",
    width:"100%",
    height:"100%"
  },
});

function Video({ classes, content: { video, name, title } }) {
  return (
    <>
      <div className={classNames(classes.video__wrapperOuter)}>
        <div className={classNames(classes.video__wrapperInner)}>
          <iframe
            src={video}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
            className={classNames(classes.video__item)}
            title={video}
          >
          </iframe>
        </div>
      </div>
      { name }
      <br/>
      { title }
    </>
  );
}

Video.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Video);

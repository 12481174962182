import React from 'react';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function H1({ content: { h1 } }) {
  const theme = useTheme()
  return (
    <Typography variant="body1" component="h1" style={{ marginBottom: theme.spacing(4) }}>
      { h1 }
    </Typography>
  );
}

export default H1;

import React from 'react';
import { NavLink } from 'react-router-dom';

import { withStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
})

function DashboardMenu( { onClick, theme } ) {
  return (
    <List disablePadding>
      <ListItem disableGutters>
        <Link component={NavLink} to="/dashboard" onClick={onClick}>Dashboard</Link>
      </ListItem>
      <ListItem disableGutters>
        <Link component={NavLink} to="/profile" onClick={onClick}>Update Profile</Link>
      </ListItem>
      <ListItem disableGutters>
        <Link component={NavLink} to="/password-change" onClick={onClick}>Change Password</Link>
      </ListItem>
    </List>
  )
}

export default withStyles(styles, { withTheme: true })(DashboardMenu)

import { unstable_createMuiStrictModeTheme as createMuiTheme , responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#fff",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1480,
    },
  },
  palette: {
    primary: {
      main: "#03172E",
      light: "#153a7c",
    },
    secondary: {
      main: "#89CCED",
      contrastText: "#fff",
    },
    error: {
      main: "#EE0000",
    },
    text: {
      primary: "#03172E",
      secondary: "#fff"
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: 'baskerville-urw, serif',
    h1: {
      fontFamily: "Danmark, serif",
    },
    h2: {
      fontFamily: "Danmark, serif",
    },
    h3: {
      fontFamily: "Danmark, serif",
    },
    h4: {
      fontFamily: "Danmark, serif",
    },
    h5: {
      fontFamily: "Danmark, serif",
      marginBottom: "1rem",
    },
    h6: {
      fontFamily: "Danmark, serif",
    },
    body1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
});

export default responsiveFontSizes(theme);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../organisms/Firebase';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import  { BootstrapInput, BootstrapButton } from '../../atoms/Elements';

const styles = {
  labelText: {
    fontSize:'1.25rem'
  },
  submitWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  checkBox: {
    color: "BLACK",
  },
  checkBoxLabel: {
    fontSize: "10px",
  },
};

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  agreementToTermsAndConditions: false,
  agreementToTermsAndConditionsTimestamp: '',
  firstName: '',
  secondName: '',
  churchOrOrganisation: '',
  country: '',
  stepsCourseMailingList: false,
  stepsResroucesMailingList: false,
  stepsPrayerMailingList: false,
  dateOfIntroduction: '',
  dateOfWeekOne: '',
  dateOfWeekFive: '',
  error: null,
};

class CreateAccountFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const {
      email,
      passwordOne,
      agreementToTermsAndConditions,
      agreementToTermsAndConditionsTimestamp,
      firstName,
      secondName,
      churchOrOrganisation,
      country,
      stepsCourseMailingList,
      stepsResroucesMailingList,
      stepsPrayerMailingList,
      dateOfIntroduction,
      dateOfWeekOne,
      dateOfWeekFive
    } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            email,
            agreementToTermsAndConditions,
            agreementToTermsAndConditionsTimestamp,
            firstName,
            secondName,
            churchOrOrganisation,
            country,
            stepsCourseMailingList,
            stepsResroucesMailingList,
            stepsPrayerMailingList,
            dateOfIntroduction,
            dateOfWeekOne,
            dateOfWeekFive,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/profile');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckChange = event => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth() < 10 ? '0' : '') + (today.getMonth()+1)+'-'+(today.getDate() < 10 ? '0' : '') + today.getDate();
    this.setState({ [event.target.name]: event.target.checked })
    this.setState({ agreementToTermsAndConditionsTimestamp: date })
  }

  render() {
    const { classes } = this.props;

    const {
      email,
      passwordOne,
      passwordTwo,
      agreementToTermsAndConditions,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      agreementToTermsAndConditions === false

    return (
      <form onSubmit={this.onSubmit}>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="email">Email</label>
          <BootstrapInput
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="passwordOne">Password</label>
          <BootstrapInput
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
          />
        </FormControl>
        <FormControl fullWidth>
          <label className={classes.labelText} htmlFor="passwordTwo">Confirm Password</label>
          <BootstrapInput
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
          />
        </FormControl>
        <br/>
        <Typography variant="h6">How will Steps use your information?</Typography>
        <Typography variant="body2">
          Steps will use your information to manage your engagement with Steps.
          Please view <a target="_blank" rel="noreferrer" href="https://staging.stepscourse.org/privacy-policy">Steps’s Privacy Policy</a> if you would like more details.
          If you have any questions or concerns about how Steps uses your information, please contact us at <a href="mailto:info@stepscourse.org">info@stepscourse.org</a>. 
        </Typography>
        <br/>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Checkbox
              className={classes.checkBox}
              name="agreementToTermsAndConditions"
              color="secondary"
              onChange={this.onCheckChange}
            />}
          label="I agree to the terms and conditions"
        />
        <br/><br/>
        <Box className={classes.submitWrapper}>
          <BootstrapButton disabled={isInvalid} type="submit">Create Account</BootstrapButton>
        </Box>
        <br/>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const CreateAccountForm = withRouter(withFirebase(withStyles(styles, { withTheme: true })(CreateAccountFormBase)));

export default CreateAccountForm;

import React from 'react';
import parse from 'html-react-parser';

import { withStyles } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  pullout: {
    backgroundColor: "#153A7C",
    color: theme.palette.common.white,
    margin: `${theme.spacing(4)}px 0`,
    padding: theme.spacing(2),

    "& a": {
      color: theme.palette.common.white,
    },
  },
})

function Pullout({ theme, classes, content: { title, pullout } }) {
  return (
    <Box className={classes.pullout}>
      {title.length > 0 &&
        <>
          <Typography variant="h4">{ title }</Typography>
          <Divider style={{ backgroundColor: "white", margin: `${theme.spacing(2)}px 0` }} />
        </>
      }
      <Typography variant="body1">
        { parse(pullout) }
      </Typography>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(Pullout)

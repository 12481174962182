import React from 'react';
import parse from 'html-react-parser';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function Table({ content: { column_one_text_align_right, table_row } }) {
  const theme = useTheme()
  const tableRow = table_row.map(table_row => {
    return (
      <tr key={table_row.id}>
        <td style={{
          width: "25%",
          paddingRight: theme.spacing(4),
          verticalAlign: "top",
          paddingBottom: theme.spacing(4),
          textAlign: `${column_one_text_align_right}`,
        }}>
          { parse(table_row.column_one) }
        </td>
        <td style={{
          marginBottom: theme.spacing(4),
          verticalAlign: "top",
          paddingBottom: theme.spacing(4),
        }}>
          { parse(table_row.column_two) }
        </td>
      </tr>
    );
  })

  return (
    <div style={{ overflowX:"auto" }} >
      <Typography variant="body1" component="table" style={{ margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0` }}>
        <tbody>
          { tableRow }
        </tbody>
      </Typography>
    </div>
  );
}

export default Table;

import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { withAuthorization } from '../Session';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';

import ProfileForm from '../../molecules/ProfileForm';

const styles = (theme) => ({
  wrapper: {
    height: "100%",
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    justifyContent:"flex-start",
    [theme.breakpoints.up('md')]: {
      maxWidth: "36%",
      margin:`${theme.spacing(12)}px auto ${theme.spacing(8)}px auto`,
      justifyContent:"center",
    },
  },
});

function Profile({classes}) {
  return (
    <Box className={classNames(classes.wrapper)}>
      <ProfileForm />
    </Box>
  )
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(styles, { withTheme: true })(Profile));

import React from 'react';

import { useTheme } from "@material-ui/styles"
import Typography from '@material-ui/core/Typography';

function H2({ content: { h2 } }) {
  const theme = useTheme()
  return (
    <Typography variant="h3" component="h2" style={{ marginBottom: theme.spacing(4) }}>
      { h2 }
    </Typography>
  );
}

export default H2;
